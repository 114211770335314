import React from "react"
import { Link } from "gatsby"
import styles from "./footer.module.css"
import CornerContainer from "../CornerContainer/CornerContainer"

class Footer extends React.PureComponent {
  render() {
    return (
      <div className={styles.wrapper}>
        <footer className={styles.container}>
          <div className={styles.address}>
            <h3 className={styles.logo}>
              undefined<span>.</span>
            </h3>
            <p>
              115 Coventry Road
              <br />
              Bethnal Green , London
              <br />
              E2 6GG
            </p>
          </div>
          <div className={styles.innerFooterBox}>
            <div className={styles.emailBox}>
              <h2>Contact</h2>
              <a href="mailto:hello@thisisundefined.com">
                hello@thisisundefined.com
              </a>
            </div>
            <div className={styles.emailBox}>
              <h2>Technology</h2>
              <div className={styles.technologies}>
                <a href="/storyblok-development">Storyblok Development</a>
                <a href="/services/progressive-prototyping/">
                  Progressive Prototyping
                </a>
                <a href="/product-development">SaaS Development</a>
              </div>
            </div>

            <div className={styles.learnMore}>
              <h2>Learn More</h2>
              <div className={styles.learnGrid}>
                <div className={styles.column}>
                  <Link to="/work">
                    <p>Work</p>
                  </Link>
                  <Link to="/contact">
                    <p>Contact</p>
                  </Link>
                </div>
                <div className={styles.columnTwo}>
                  <Link to="/services">Services</Link>

                  <a
                    href="https://www.iubenda.com/privacy-policy/27576097"
                    className="iubenda-black iubenda-embed "
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Privacy Policy"
                  >
                    Privacy Policy
                  </a>
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `
						(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
					`,
                    }}
                  ></script>
                </div>
                <div className={styles.columnTwo}>
                  <Link to="/join-us">Join Us</Link>
                  <Link to="/blog">Blog</Link>
                </div>
              </div>
            </div>
          </div>
        </footer>

        <span className={styles.legal}>
          © Copyright 2023 This is Undefined Ltd.. All rights reserved
        </span>
      </div>
    )
  }
}

export default Footer
